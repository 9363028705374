import { useEffect, useRef, useState } from 'react'
import { AddressSuggestions, DaDataAddress, DaDataSuggestion } from 'react-dadata'
import { useForm } from 'react-hook-form'

import { Modify } from 'types/types'

import { RUSSIA_NATIONAL_ID } from '@common/constants'
import regexp from '@common/regexp'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useProfileApiHandling from '@hooks/useProfileApiHandling'
import {
  getNumAndCyrillicValid,
  getNumAndCyrillcAndLatinValid,
  getNumAndCyrillcAndDashValid,
  getNumAndCyrillicValidHouse,
  normalizeYearTo4Characters,
} from '@lib/validators'
import { Residence, ResidenceFormData, SelectOption } from '@models'
import { getResidence } from '@redux/action-types'
import { selectLocalizedViewerProfile } from '@redux/actions/selectors'
import api from '@services/api'

const useResidenceForm = () => {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    setValue,
    trigger,
    getValues,
    ...rest
  } = useForm<Residence>({ mode: 'all' })

  const dispatch = useAppDispatch()

  const profileData = useAppSelector(selectLocalizedViewerProfile)

  const { callApi, loading } = useProfileApiHandling({
    setError,
    apiFn: api.client.updateResidence,
    onSuccessCb: () => dispatch(getResidence()),
  })

  const watchAllFields = watch()
  const actisreg = Boolean(watch('actisreg'))

  const isRussia = (key: keyof Residence) => {
    const national = watch(key) as unknown as SelectOption | undefined
    return national?.value === RUSSIA_NATIONAL_ID
  }

  const isRegRussia = isRussia('nationalRegistration')
  const isActRussia = isRussia('nationalActual')

  const isRussianCitizenship =
    profileData?.userNational[0].nationalityId === RUSSIA_NATIONAL_ID || isRegRussia

  useEffect(() => {
    if (!isRussianCitizenship) {
      setValue('actisreg', true)
    }
  }, [watch('actisreg')])

  const fields = {
    kladr1: register('kladr1'),
    kladr2: register('kladr2'),

    kladrActual: register('kladrActual'),
    regionCodeActual: register('regionCodeActual'),
    districtCodeActual: register('districtCodeActual'),
    localityCodeActual: register('localityCodeActual'),
    streetCodeActual: register('streetCodeActual'),

    kladrRegistration: register('kladrRegistration'),
    regionCodeRegistration: register('regionCodeRegistration'),
    districtCodeRegistration: register('districtCodeRegistration'),
    localityCodeRegistration: register('localityCodeRegistration'),
    streetCodeRegistration: register('streetCodeRegistration'),

    nationalRegistration: register('nationalRegistration', {
      required: terms.REQUIRED_FIELD,
    }),
    cityRegistration: register('cityRegistration', {
      required: terms.REQUIRED_FIELD,
      pattern: {
        value: regexp.checkingSymbolsNumberAndCyrillicDashInString,
        message: terms.CYRILLIC_AND_NUMBERS_ONLY,
      },
    }),
    apiFias: register('apiFias', {
      required: {
        value: isRegRussia,
        message: terms.REQUIRED_FIELD,
      },
    }),
    dadataField: register('dadataField', {
      required: {
        value: isRegRussia,
        message: terms.REQUIRED_FIELD,
      },
    }),
    dadataValue: register('dadataValue', {
      required: {
        value: isRegRussia,
        message: terms.REQUIRED_FIELD,
      },
    }),
    dadataUnrestrictedValue: register('dadataUnrestrictedValue', {
      required: {
        value: isRegRussia,
        message: terms.REQUIRED_FIELD,
      },
    }),
    dadataFieldActual: register('dadataFieldActual', {
      required: {
        value: isActRussia,
        message: terms.REQUIRED_FIELD,
      },
    }),
    dadataValueActual: register('dadataFieldActual', {
      required: {
        value: isActRussia,
        message: terms.REQUIRED_FIELD,
      },
    }),
    dadataUnrestrictedValueActual: register('dadataFieldActual', {
      required: {
        value: isActRussia,
        message: terms.REQUIRED_FIELD,
      },
    }),

    apiKladr: register('apiKladr', {
      required: {
        value: isRegRussia,
        message: terms.REQUIRED_CHECK_ADDRESS,
      },
    }),
    apiFiasActual: register('apiFiasActual', {
      required: {
        value: isActRussia,
        message: terms.REQUIRED_FIELD,
      },
    }),
    apiKladrActual: register('apiKladrActual', {
      required: {
        value: isActRussia,
        message: terms.REQUIRED_CHECK_ADDRESS,
      },
    }),

    regionRegistration: register('regionRegistration', {
      required: isRegRussia ? terms.REQUIRED_FIELD : undefined,
      pattern: {
        value: regexp.checkingSymbolsNumberAndCyrillicDashInString,
        message: terms.CYRILLIC_AND_NUMBERS_ONLY,
      },
    }),
    districtRegistration: register('districtRegistration', {
      pattern: {
        value: regexp.checkingSymbolsNumberAndCyrillicDashInString,
        message: terms.CYRILLIC_AND_NUMBERS_ONLY,
      },
    }),
    streetRegistration: register('streetRegistration', {
      disabled: watch('noStreetRegistration'),
      required: {
        value: isRegRussia || !watchAllFields.noStreetRegistration,
        message: terms.REQUIRED_FIELD,
      },
      pattern: {
        value: regexp.checkingSymbolsNumberAndCyrillicDashInString,
        message: terms.CYRILLIC_AND_NUMBERS_ONLY,
      },
    }),
    houseRegistration: register('houseRegistration', {
      required: terms.REQUIRED_FIELD,
      pattern: {
        value: regexp.checkingSymbolsNumberAndCyrillicDashInString,
        message: terms.CYRILLIC_AND_NUMBERS_ONLY,
      },
    }),
    buildingRegistration: register('buildingRegistration', {
      pattern: {
        value: regexp.checkingSymbolsNumberAndCyrillicDashInString,
        message: terms.CYRILLIC_AND_NUMBERS_ONLY,
      },
    }),
    apartmentRegistration: register('apartmentRegistration', {
      pattern: {
        value: regexp.checkingSymbolsNumberAndCyrillicDashInString,
        message: terms.CYRILLIC_AND_NUMBERS_ONLY,
      },
    }),
    postcodeRegistration: register('postcodeRegistration', {
      required: {
        value: !isRussianCitizenship,
        message: terms.REQUIRED_FIELD,
      },
      ...getNumAndCyrillcAndLatinValid(),
    }),
    nationalActual: register('nationalActual', {
      required: {
        value: !actisreg,
        message: terms.REQUIRED_FIELD,
      },
    }),
    cityActual: register('cityActual', {
      required: {
        value: isActRussia,
        message: terms.REQUIRED_FIELD,
      },
      pattern: {
        value: regexp.checkingSymbolsNumberAndCyrillicDashInString,
        message: terms.CYRILLIC_AND_NUMBERS_ONLY,
      },
    }),
    districtActual: register('districtActual', {
      pattern: {
        value: regexp.checkingSymbolsNumberAndCyrillicDashInString,
        message: terms.CYRILLIC_AND_NUMBERS_ONLY,
      },
    }),
    regionActual: register('regionActual', {
      required: {
        value: !actisreg && isActRussia,
        message: terms.REQUIRED_FIELD,
      },
      pattern: {
        value: regexp.checkingSymbolsNumberAndCyrillicDashInString,
        message: terms.CYRILLIC_AND_NUMBERS_ONLY,
      },
    }),
    streetActual: register('streetActual', {
      disabled: watch('noStreetActual'),
      required: {
        value: !actisreg && isActRussia,
        message: terms.REQUIRED_FIELD,
      },
      pattern: {
        value: regexp.checkingSymbolsNumberAndCyrillicDashInString,
        message: terms.CYRILLIC_AND_NUMBERS_ONLY,
      },
    }),
    houseActual: register('houseActual', {
      required: {
        value: !actisreg && !watchAllFields.noStreetActual,
        message: terms.REQUIRED_FIELD,
      },
      pattern: {
        value: regexp.checkingSymbolsNumberAndCyrillicDashInString,
        message: terms.CYRILLIC_AND_NUMBERS_ONLY,
      },
    }),
    buildingActual: register('buildingActual', {
      pattern: {
        value: regexp.checkingSymbolsNumberAndCyrillicDashInString,
        message: terms.CYRILLIC_AND_NUMBERS_ONLY,
      },
    }),
    needHostel: register('needHostel', {
      required: terms.REQUIRED_FIELD,
    }),
    apartmentActual: register('apartmentActual', {
      pattern: {
        value: regexp.checkingSymbolsNumberAndCyrillicDashInString,
        message: terms.CYRILLIC_AND_NUMBERS_ONLY,
      },
    }),
    postcodeActual: register('postcodeActual'),
    noStreetActual: register('noStreetActual', {
      onChange: ({ target: { checked } }) => {
        if (checked) {
          setValue('streetActual', null)
          setValue('streetCodeActual', '0000')
          clearErrors('streetActual')

          setValue('kladrActual', null)
          setValue('kladrActual', null)
          clearErrors('houseActual')
        }
      },
    }),
    noStreetRegistration: register('noStreetRegistration', {
      onChange: ({ target: { checked } }) => {
        if (checked) {
          setValue('streetRegistration', null)
          setValue('streetCodeRegistration', '0000')
          clearErrors('streetRegistration')

          setValue('houseRegistration', null)
          setValue('kladrRegistration', null)
          clearErrors('houseRegistration')
        }
      },
    }),
    actisreg: register('actisreg', {
      onChange: (e) => {
        const { checked } = e.target
        const values = getValues()
        if (checked) {
          setValue('cityActual', values.cityRegistration)
          setValue('regionActual', values.regionRegistration)
          setValue('districtActual', values.districtRegistration)
          setValue('houseActual', values.houseRegistration)
          setValue('streetActual', values.streetRegistration)
          setValue('buildingActual', values.buildingRegistration)
          setValue('nationalActual', values.nationalRegistration)
          setValue('postcodeActual', values.postcodeRegistration)
          setValue('apartmentActual', values.apartmentRegistration)
          setValue('apiFiasActual', values.apiFias)
          setValue('dadataFieldActual', values.dadataField)
          setValue('dadataValueActual', values.dadataValue)
          setValue('dadataUnrestrictedValueActual', values.dadataUnrestrictedValue)
          setValue('apiKladrActual', values.apiKladr)
          clearErrors('nationalActual')
          clearErrors('houseActual')
          clearErrors('cityActual')
          clearErrors('streetActual')
        }
      },
    }),
  }
  const [value, setValue2] = useState<DaDataSuggestion<DaDataAddress> | undefined>()

  const suggestionsRef = useRef<AddressSuggestions>(null)

  const handleClick = () => {
    const values = getValues()

    if (suggestionsRef.current) {
      if (
        values.regionRegistration != null &&
        values.cityRegistration != null &&
        values.streetRegistration != null &&
        values.houseRegistration != null
      )
        suggestionsRef?.current?.setInputValue(
          values.regionRegistration +
            ' ' +
            values.cityRegistration +
            ' ' +
            values.streetRegistration +
            ' ' +
            values.houseRegistration +
            ' '
        )
      suggestionsRef.current.render()
    }
    setValue('apiFias', null)
    setValue('dadataField', null)
    setValue('dadataValue', null)
    setValue('dadataUnrestrictedValue', null)
    setValue('apiKladr', null)
  }

  const handleFocus = (e) => {
    const values = getValues()
    if (suggestionsRef.current) {
      suggestionsRef.current.setInputValue(
        values?.regionRegistration +
          ' ' +
          values?.cityRegistration +
          ' ' +
          values?.streetRegistration +
          ' ' +
          e.target.value
      )
      suggestionsRef.current.focus()
      suggestionsRef.current.render()
    }
  }

  const suggestionsRefActual = useRef<AddressSuggestions>(null)

  const handleClickActual = () => {
    const values = getValues()

    if (suggestionsRefActual.current) {
      if (
        values.regionActual != null &&
        values.cityActual != null &&
        values.streetActual != null &&
        values.houseActual != null
      )
        suggestionsRefActual?.current?.setInputValue(
          values.regionActual +
            ' ' +
            values.cityActual +
            ' ' +
            values.streetActual +
            ' ' +
            values.houseActual +
            ' '
        )
      suggestionsRefActual.current.render()
    }
    setValue('apiFiasActual', '')
    setValue('dadataFieldActual', '')
    setValue('dadataValueActual', '')
    setValue('dadataUnrestrictedValueActual', '')
    setValue('apiKladrActual', '')
  }

  const handleFocusActual = (e) => {
    const values = getValues()
    if (suggestionsRefActual.current) {
      suggestionsRefActual.current.setInputValue(
        values?.regionActual +
          ' ' +
          values?.cityActual +
          ' ' +
          values?.streetActual +
          ' ' +
          e.target.value
      )
      suggestionsRefActual.current.focus()
      suggestionsRefActual.current.render()
    }
  }

  const handleOption = (value) => {
    if (value != undefined) {
      if (value.data.region_with_type) {
        setValue('regionRegistration', value.data.region_with_type)
        actisreg ? setValue('regionActual', value.data.region_with_type) : ''
      }
      if (value.data.city_with_type) {
        setValue('cityRegistration', value.data.city_with_type)
        actisreg ? setValue('cityActual', value.data.city_with_type) : ''
      }
      if (value.data.federal_district) {
        setValue('districtRegistration', value.data.federal_district)
        actisreg ? setValue('districtActual', value.data.federal_district) : ''
      }
      if (value.data.street_with_type) {
        setValue('streetRegistration', value.data.street_with_type)
        actisreg ? setValue('streetActual', value.data.street_with_type) : ''
      }
      if (value.data.house) {
        setValue('houseRegistration', value.data.house)
        actisreg ? setValue('houseActual', value.data.house) : ''
      }
      if (value.data.flat) {
        setValue('apartmentRegistration', value.data.flat)
        actisreg ? setValue('apartmentActual', value.data.flat) : ''
      } else {
        setValue('apartmentRegistration', '')
        actisreg ? setValue('apartmentActual', '') : ''
      }
      if (value.data.block_type && value.data.block) {
        setValue(
          'buildingRegistration',
          value.data.block_type + ' ' + value.data.block
        )
        actisreg
          ? setValue(
              'buildingActual',
              value.data.block_type + ' ' + value.data.block
            )
          : ''
      } else {
        setValue('buildingRegistration', '')
        actisreg ? setValue('buildingActual', '') : ''
      }
      if (value.data.postal_code) {
        setValue('postcodeRegistration', value.data.postal_code)
        actisreg ? setValue('postcodeActual', value.data.postal_code) : ''
      }
      if (value.data.fias_id) {
        setValue('apiFias', value.data.fias_id)
        actisreg ? setValue('apiFiasActual', value.data.fias_id) : ''
      }
      if (value.data) {
        setValue('dadataField', value.data)
        actisreg ? setValue('dadataFieldActual', value.data) : ''
      }
      if (value.value) {
        setValue('dadataValue', value.value)
        actisreg ? setValue('dadataValueActual', value.value) : ''
      }
      if (value.unrestricted_value) {
        setValue('dadataUnrestrictedValue', value.unrestricted_value)
        actisreg
          ? setValue('dadataUnrestrictedValueActual', value.unrestricted_value)
          : ''
      }
      if (value.data.kladr_id) {
        setValue('apiKladr', value.data.kladr_id)
        actisreg ? setValue('apiKladrActual', value.data.kladr_id) : ''
      }
    }
  }
  const handleOptionActual = (value) => {
    if (value != undefined) {
      if (value.data.region_with_type) {
        setValue('regionActual', value.data.region_with_type)
      }
      if (value.data.city_with_type) {
        setValue('cityActual', value.data.city_with_type)
      }
      if (value.data.federal_district) {
        setValue('districtActual', value.data.federal_district)
      }
      if (value.data.street_with_type) {
        setValue('streetActual', value.data.street_with_type)
      }
      if (value.data.house) {
        setValue('houseActual', value.data.house)
      }
      if (value.data.flat) {
        setValue('apartmentActual', value.data.flat)
      } else {
        setValue('apartmentActual', '')
      }
      if (value.data.block_type && value.data.block) {
        setValue('buildingActual', value.data.block_type + ' ' + value.data.block)
      } else {
        setValue('buildingActual', '')
      }
      if (value.data.postal_code) {
        setValue('postcodeActual', value.data.postal_code)
      }
      if (value.data.fias_id) {
        setValue('apiFiasActual', value.data.fias_id)
      }
      if (value.data) {
        setValue('dadataFieldActual', value.data)
      }
      if (value.value) {
        setValue('dadataValueActual', value.value)
      }
      if (value.data) {
        setValue('dadataUnrestrictedValueActual', value.unrestricted_value)
      }
      if (value.data.kladr_id) {
        setValue('apiKladrActual', value.data.kladr_id)
      }
    }
  }

  const onSubmit = handleSubmit((data: any) => {
    const normalizedData: Modify<Residence, { needHostel: boolean }> = {
      ...data,
      cityRegistration:
        typeof data.cityRegistration === 'string'
          ? data.cityRegistration
          : data.cityRegistration?.label,
      cityActual:
        typeof data.cityActual === 'string'
          ? data.cityActual
          : data.cityActual?.label,

      districtRegistration:
        typeof data.districtRegistration === 'string'
          ? data.districtRegistration
          : data.districtRegistration?.label,
      districtActual:
        typeof data.districtActual === 'string'
          ? data.districtActual
          : data.districtActual?.label,

      regionRegistration:
        typeof data.regionRegistration === 'string'
          ? data.regionRegistration
          : data.regionRegistration?.label,
      regionActual:
        typeof data.regionActual === 'string'
          ? data.regionActual
          : data.regionActual?.label,

      streetRegistration:
        typeof data.streetRegistration === 'string'
          ? data.streetRegistration
          : data.streetRegistration?.label,
      streetActual:
        typeof data.streetActual === 'string'
          ? data.streetActual
          : data.streetActual?.label,

      houseRegistration:
        typeof data.houseRegistration === 'string'
          ? data.houseRegistration
          : data.houseRegistration?.label,
      houseActual:
        typeof data.houseActual === 'string'
          ? data.houseActual
          : data.houseActual?.label,

      nationalActual: data.nationalActual?.value,
      nationalRegistration: data.nationalRegistration?.value,
      needHostel: data.needHostel === 'need',
    }

    if (normalizedData.actisreg) {
      normalizedData.kladrActual = normalizedData.kladrRegistration
      normalizedData.cityActual = normalizedData.cityRegistration
      normalizedData.regionActual = normalizedData.regionRegistration
      normalizedData.districtActual = normalizedData.districtRegistration
      normalizedData.houseActual = normalizedData.houseRegistration
      normalizedData.streetActual = normalizedData.streetRegistration
      normalizedData.noStreetActual = normalizedData.noStreetRegistration
      normalizedData.buildingActual = normalizedData.buildingRegistration
      normalizedData.postcodeActual = normalizedData.postcodeRegistration
      normalizedData.nationalActual = normalizedData.nationalRegistration
      normalizedData.apartmentActual = normalizedData.apartmentRegistration
      normalizedData.apiFiasActual = normalizedData.apiFias
      normalizedData.dadataFieldActual = normalizedData.dadataField
      normalizedData.dadataValueActual = normalizedData.dadataValue
      normalizedData.dadataUnrestrictedValueActual =
        normalizedData.dadataUnrestrictedValue
      normalizedData.apiKladrActual = normalizedData.apiKladr
    }

    callApi(normalizedData)
  })

  return {
    value,
    setValue2,
    suggestionsRef,
    suggestionsRefActual,
    handleOption,
    handleClick,
    handleFocus,
    handleOptionActual,
    handleFocusActual,
    handleClickActual,
    watchAllFields,
    isRegRussia,
    isActRussia,
    isRussianCitizenship,
    fields,
    onSubmit,
    loading,
    setValue,
    getValues,
    watch,
    actisreg,
    clearErrors,
    ...rest,
  }
}

export default useResidenceForm
