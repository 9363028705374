import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import InputMask from 'react-input-mask'

import terms from '@common/terms'
import ProfileForm from '@components/ProfileForm'
import ProfileSection from '@components/ProfileSection'
import useAppSelector from '@hooks/useAppSelector'
import usePassportDataFormInitialization from '@hooks/usePassportDataFormInitialization'
import usePassportForm from '@hooks/usePassportForm'
import useProfileRoutesContext from '@hooks/useProfileRoutesContext'
import useUploadDocuments from '@hooks/useUploadDocuments'
import { validateDate, validIssueDate } from '@lib/validators'
import { SelectOption } from '@models'
import { selectIsQuestionnaireBlockProfile } from '@redux/actions/selectors'
import Checkbox from '@ui/Checkbox'
import FileUploader from '@ui/FileUploader'
import Input from '@ui/Input'
import Select from '@ui/Select'

const PassportDataForm = (passportIds) => {
  const {
    fields,
    control,
    setValue,
    watch,
    onSubmit,
    formState: { errors },
    isSeriesRequired,
    isDateOfExpirationExists,
    isRussianPassport,
    isRussianForeignPassport,
    isForeignPassport,
    listIssuingAuthority,
    IssuingAuthorityOnChange,
    isNameAuthorityMissing,
    changeMissingDocValue,
  } = usePassportForm(passportIds)
  const readOnly = useAppSelector(selectIsQuestionnaireBlockProfile)
  const { onNextPageNavigate } = useProfileRoutesContext()

  const haveQuotaMinobr = watch('quota_minobr')

  const {
    typeIdentityDocumentOptions,
    typeIdentityDocumentOptionsRus,
    isNationalRussia,
    personalData,
  } = usePassportDataFormInitialization(passportIds, { setValue })

  const {
    docs: passportDocs,
    onDrop: onDropPassport,
    onChange: onChangePassport,
    onDelete: onDeletePassport,
    onUploadDocs: onUploadPassport,
    getUploadedDoc: getPassportDoc,
    documentLoaded: passportLoaded,
  } = useUploadDocuments('PASSPORT', terms.COPY_OF_PASSPORT)

  const {
    docs: vnzDocs,
    onDrop: onDropVnz,
    onChange: onChangeVnz,
    onDelete: onDeleteVnz,
    onUploadDocs: onUploadVnz,
    getUploadedDoc: getVnzDoc,
    documentLoaded: vnzLoaded,
  } = useUploadDocuments('VNZ', terms.VNZ)

  const {
    docs: educationDocs,
    onDrop: onDropEducationDocs,
    onChange: onChangeEducationDocs,
    onDelete: onDeleteEducationDocs,
    onUploadDocs: onUploadEducationDocs,
    getUploadedDoc: getEducationDocs,
    documentLoaded: educationDocsLoaded,
  } = useUploadDocuments('EDUCATION_DOCUMENT')

  const {
    docs: quotaDocs,
    onDrop: onDropQuota,
    onChange: onChangeQuota,
    onDelete: onDeleteQuota,
    onUploadDocs: onUploadQuota,
    getUploadedDoc: getQuotaDoc,
    documentLoaded: quotaLoaded,
  } = useUploadDocuments('QUOTA_MINOBR')

  useEffect(() => {
    !vnzLoaded && getVnzDoc()
    !passportLoaded && getPassportDoc()
    !quotaLoaded && getQuotaDoc()
    !educationDocsLoaded && getEducationDocs()
  }, [])

  return (
    <ProfileForm
      onSubmit={
        readOnly
          ? (e) => {
              e.preventDefault()
              onNextPageNavigate()
            }
          : (e) => {
              onSubmit(e)
              if (
                Object.keys(errors).length === 0 &&
                validateDate(
                  watch('dateOfIssue'),
                  personalData?.dateBirthday || ''
                ) &&
                validIssueDate(watch('documentValidPeriod') || null)
              ) {
                watch('haveVnz') && onUploadVnz(() => getVnzDoc())
                watch('quota_minobr') && onUploadQuota(() => getQuotaDoc())
                onUploadPassport(() => getPassportDoc())
                onUploadEducationDocs(() => getEducationDocs())
              }
            }
      }
    >
      <p>{!isNationalRussia ? terms.TRANSLATION_OF_THE_IDENTITY_DOCUMENT : ''}</p>
      <ProfileSection>
        <div className="profile-form__fields">
          <Controller
            name="typeIdentityDocumentId"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                className="profile-form__field"
                {...(field as unknown)}
                options={
                  isNationalRussia
                    ? typeIdentityDocumentOptionsRus
                    : typeIdentityDocumentOptions
                }
                placeholder={terms.DOCUMENT_TYPE}
                error={errors.typeIdentityDocumentId?.message}
                markRequired={true}
                isClearable={false}
                isDisabled={readOnly}
              />
            )}
          />
          <div
            className={`profile-form__field  ${
              isRussianPassport
                ? 'profile-form__field--containerline'
                : 'profile-form__field--container'
            }`}
          >
            {isRussianPassport ? (
              <div className="input-container input-container--active profile-form__input--fb-55">
                <div className="input-container__inner">
                  <InputMask
                    mask={
                      isRussianPassport || isRussianForeignPassport ? '9999' : ''
                    }
                    className=" input-container__input"
                    markRequired={isSeriesRequired}
                    {...fields.serialDocument}
                    disabled={readOnly}
                    error={errors.serialDocument?.message}
                  />
                  <label className="input-container__placeholder input-container__placeholder--required">
                    {terms.SERIES}
                  </label>
                </div>
              </div>
            ) : (
              <Input
                className={`profile-form__input profile-form__input--fb-50 ${
                  isForeignPassport && 'profile-form__input--hint-with-spaces'
                }`}
                placeholder={terms.SERIES}
                markRequired={true}
                hint={
                  isNationalRussia
                    ? watch('typeIdentityDocumentId')
                      ? terms.HINT_SERIES_FOREIGN
                      : ''
                    : terms.HINT_SERIES_FOREIGN
                }
                {...fields.serialDocument}
                disabled={readOnly}
                error={errors.serialDocument?.message}
              />
            )}

            {isRussianPassport ? (
              <div className="input-container input-container--active profile-form__input--fb-55">
                <div className="input-container__inner">
                  <InputMask
                    mask={
                      isRussianPassport || isRussianForeignPassport ? '999999' : ''
                    }
                    className=" input-container__input"
                    markRequired={isSeriesRequired}
                    {...fields.numberDocument}
                    disabled={readOnly}
                    error={errors.numberDocument?.message}
                  />
                  <label className="input-container__placeholder input-container__placeholder--required">
                    {terms.NUMBER}
                  </label>
                </div>
              </div>
            ) : (
              <Input
                className={`profile-form__input profile-form__input--fb-50 ${
                  isForeignPassport && 'profile-form__input--hint-with-spaces'
                }`}
                placeholder={terms.NUMBER}
                markRequired={true}
                {...fields.numberDocument}
                disabled={readOnly}
                error={errors.numberDocument?.message}
              />
            )}
          </div>
          {isRussianPassport && (
            <div className="input-container profile-form__field input-container--active">
              <div className="input-container__inner">
                <InputMask
                  mask={'999-999'}
                  className=" input-container__input"
                  placeholder={terms.DIVISION_CODE}
                  {...fields.divisionCode}
                  disabled={readOnly}
                  onChange={IssuingAuthorityOnChange}
                  error={errors.divisionCode?.message}
                />
                <label className="input-container__placeholder input-container__placeholder--required">
                  {terms.DIVISION_CODE}
                </label>
              </div>
            </div>
          )}

          {isRussianPassport && (
            <Controller
              name="whoIssueDocument"
              control={control}
              render={({ field }) => (
                <div className="profile-form__field profile-form__missing-doc">
                  <Select<SelectOption>
                    className="profile-form__field"
                    {...(field as unknown)}
                    options={listIssuingAuthority}
                    placeholder={terms.ISSUING_AUTHORITY}
                    error={
                      isNameAuthorityMissing ? '' : errors.whoIssueDocument?.message
                    }
                    markRequired={!isNameAuthorityMissing}
                    isClearable={true}
                    isDisabled={readOnly || isNameAuthorityMissing}
                  />

                  <Checkbox
                    text={terms.NAME_AUTHORITY_ISSUED_IDENTITY_DOCUMENT_MISSING}
                    className="profile-form__checkbox profile-form__checkbox--mt-24 profile-form__checkbox-missing-doc"
                    {...isNameAuthorityMissing}
                    onChange={changeMissingDocValue}
                  />
                  {isNameAuthorityMissing && (
                    <Input
                      className="profile-form__field"
                      placeholder={terms.ISSUING_AUTHORITY}
                      markRequired={true}
                      {...fields.whoIssueDocument}
                      disabled={readOnly}
                      error={errors.whoIssueDocument?.message}
                    />
                  )}
                </div>
              )}
            />
          )}
          {!isRussianPassport && (
            <Input
              className="profile-form__field"
              placeholder={terms.ISSUING_AUTHORITY}
              markRequired={true}
              {...fields.whoIssueDocument}
              disabled={readOnly}
              error={errors.whoIssueDocument?.message}
            />
          )}
          <Input
            className="profile-form__field"
            placeholder={terms.DATE_OF_ISSUE}
            type="date"
            markRequired={true}
            {...fields.dateOfIssue}
            data-minimum-value={personalData?.dateBirthday}
            disabled={readOnly}
            error={errors.dateOfIssue?.message}
          />
          {isDateOfExpirationExists && (
            <Input
              className="profile-form__field"
              placeholder={terms.DATE_OF_EXPIRATION}
              type="date"
              markRequired={true}
              {...fields.documentValidPeriod}
              disabled={readOnly}
              error={errors.documentValidPeriod?.message}
            />
          )}
          <Input
            className="profile-form__field"
            placeholder={terms.PLACE_OF_BIRTH}
            markRequired={true}
            {...fields.placeBirthday}
            disabled={readOnly}
            error={errors.placeBirthday?.message}
          />

          <ProfileSection
            title={
              isRussianPassport || isRussianForeignPassport
                ? terms.COPY_OF_PASSPORT
                : terms.NO_RUSSIA_PASSPORT
            }
            markRequired={true}
            fileUploadingHint
            isHintTooltip={false}
            hint={
              isRussianPassport || isRussianForeignPassport
                ? terms.PASSPORT_DATA_COPY_PASSPORT_HINT
                : ''
            }
          >
            <FileUploader
              files={passportDocs}
              disabled={readOnly}
              onDrop={onDropPassport}
              onChange={onChangePassport}
              onDelete={onDeletePassport}
              className="profile-form__file-uploader"
            />
          </ProfileSection>

          {!isRussianPassport && !isRussianForeignPassport && (
            <ProfileSection
              title={terms.NOTARIZED_PASSPORT}
              fileUploadingHint
              markRequired={false}
            >
              <FileUploader
                aria-required={false}
                files={educationDocs}
                onDrop={onDropEducationDocs}
                onChange={onChangeEducationDocs}
                onDelete={onDeleteEducationDocs}
                className="profile-form__file-uploader"
              />
            </ProfileSection>
          )}
        </div>
      </ProfileSection>

      <ProfileSection>
        {!isNationalRussia && (
          <div>
            <div className="profile-form__checkbox profile-form__checkbox--mt-24 quota-line">
              <Checkbox
                text={terms.QUOTA_MINOBR}
                {...fields.quota_minobr}
                disabled={readOnly}
              />

              {haveQuotaMinobr && (
                <Input
                  required={true}
                  markRequired={true}
                  className="profile-form__field--reg-number"
                  placeholder={terms.REG_NUMBER_EDUINRU}
                  {...fields.reg_number_eduinru}
                  hint={terms.HINT_NUMBER_EDUINRU}
                  error={errors.reg_number_eduinru?.message}
                />
              )}
            </div>

            <div className="profile-form__field">
              <>
                <Checkbox
                  text={terms.I_AM_COMPATRIOT}
                  className="profile-form__checkbox profile-form__checkbox--mt-24"
                  {...fields.compatriot}
                  disabled={readOnly}
                />
                <p className={'input-container__hint'}>
                  {terms.I_AM_COMPATRIOT_HINT}
                </p>
              </>
            </div>

            <div className="profile-form__field">
              <Checkbox
                text={terms.I_HAVE_VNZ}
                className="profile-form__checkbox profile-form__checkbox--mt-24"
                disabled={readOnly}
                {...fields.haveVnz}
              />
              <p className={'input-container__hint'}>{terms.I_HAVE_VNZ_HINT}</p>
            </div>
          </div>
        )}
      </ProfileSection>

      {watch('haveVnz') && (
        <ProfileSection title={terms.VNZ} fileUploadingHint>
          <div className="profile-form__fields">
            <Input
              className="profile-form__field"
              placeholder={terms.DOCUMENT_NUMBER}
              {...fields.vnzNumber}
              disabled={readOnly}
              markRequired={true}
              error={errors.vnzNumber?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={terms.ISSUING_AUTHORITY}
              {...fields.vnzWhoIssue}
              disabled={readOnly}
              markRequired={true}
              error={errors.vnzWhoIssue?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={terms.DATE_OF_ISSUE}
              type="date"
              {...fields.vnzIssue}
              disabled={readOnly}
              markRequired={true}
              error={errors.vnzIssue?.message}
            />
            <Input
              className="profile-form__field"
              placeholder={terms.DATE_OF_EXPIRATION}
              type="date"
              {...fields.vnzValidPeriod}
              disabled={readOnly}
              markRequired={true}
              error={errors.vnzValidPeriod?.message}
            />
          </div>
          <div>
            <b className="input-container__placeholder--required">
              {terms.VNZ_COPY}
            </b>
            <br />
            <br />
            <p>{terms.VNZ_DESCRIPTION}</p>
          </div>

          <FileUploader
            files={vnzDocs}
            disabled={readOnly}
            onDrop={onDropVnz}
            onChange={onChangeVnz}
            onDelete={onDeleteVnz}
            className="profile-form__file-uploader"
          />
        </ProfileSection>
      )}
    </ProfileForm>
  )
}

export default PassportDataForm
