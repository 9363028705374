import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import ModeratorDocumentsPage from '../ModeratorDocumentsPage'
import ModeratorDataList from '@components/ModeratorDataList'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { DataList } from '@models'
import { getModeratorStatementsPassportData } from '@redux/action-types'
import { selectModeratorStatementsPassportData } from '@redux/actions/selectors'
import Checkbox from '@ui/Checkbox'
import { formatDateYYYYMMDD } from '@utils/date'

const ModeratorPassportDataPage = () => {
  const dispatch = useAppDispatch()

  const { id, userId } = useParams<{ id?: string; userId?: string }>()

  useEffect(() => {
    if (id || userId) {
      dispatch(getModeratorStatementsPassportData({ id: id, userId: userId }))
    }
  }, [id, userId])

  const passport = useAppSelector(selectModeratorStatementsPassportData) || []

  const list: DataList = [
    {
      title: 'Название документа:',
      label:
        (passport.length &&
          passport[0]?.typeIdentityDoc?.typeIdentityDocTranslate[0]?.name) ||
        '-',
    },
    {
      title: 'Серия:',
      label: (passport.length && passport[0]?.serialDocument) || '-',
    },
    {
      title: 'Номер:',
      label: (passport.length && passport[0]?.numberDocument) || '-',
    },
    {
      title: 'Срок действия:',
      label:
        passport.length && passport[0]?.documentValidPeriod
          ? formatDateYYYYMMDD(passport[0]?.documentValidPeriod)
          : '-',
    },
    {
      title: 'Код подразделения:',
      label: (passport.length && passport[0]?.divisionCode) || '-',
    },
    {
      title: 'Когда выдан:',
      label:
        passport.length && passport[0]?.dateOfIssue
          ? formatDateYYYYMMDD(passport[0]?.dateOfIssue)
          : '-',
    },
    {
      title: 'Кем выдан:',
      label: (passport.length && passport[0]?.whoIssueDocument) || '-',
    },
    // {
    //   title: 'Страна рождения:',
    //   label:
    //     (passport.length &&
    //       passport[0]?.nationality?.nationalityTranslate[0]?.name) ||
    //     '-',
    // },
    {
      title: 'Место рождения:',
      label: (passport.length && passport[0]?.placeBirthday) || '-',
    },
  ]

  return (
    <div className={'moderator-data-list'}>
      <ModeratorDataList list={list} />
      {passport.length && (
        <>
          <Checkbox
            text={'Имеет вид на жительство'}
            checked={(passport.length && passport[0]?.haveVnz) || false}
            readOnly
          />
          {passport[0]?.haveVnz && (
            <ModeratorDataList
              list={[
                {
                  title: 'Номер:',
                  label: (passport.length && passport[0]?.vnzNumber) || '-',
                },
                {
                  title: 'Кем выдан:',
                  label: (passport.length && passport[0]?.vnzWhoIssue) || '-',
                },
                {
                  title: 'Когда выдан:',
                  label:
                    passport.length && passport[0]?.vnzIssue
                      ? formatDateYYYYMMDD(passport[0]?.vnzIssue)
                      : '-',
                },
                {
                  title: 'Срок действия:',
                  label:
                    passport.length && passport[0]?.vnzValidPeriod
                      ? formatDateYYYYMMDD(passport[0]?.vnzValidPeriod)
                      : '-',
                },
              ]}
            />
          )}
          <Checkbox
            text={'Поступаю по квоте Минобрнауки России'}
            checked={(passport.length && passport[0]?.regNumberEduinru) || false}
            readOnly
          />
          {passport[0]?.regNumberEduinru && (
            <ModeratorDataList
              list={[
                {
                  title: 'Регистрационный номер:',
                  label: (passport.length && passport[0]?.regNumberEduinru) || '-',
                },
              ]}
            />
          )}
          <Checkbox
            text={'Являюсь соотечественником'}
            checked={(passport.length && passport[0]?.user.compatriot) || false}
            readOnly
          />
        </>
      )}
      <ModeratorDocumentsPage
        params={[
          {
            typeDoc: 'copy_passport',
            title:
              'Скан 2-3 страниц паспорта или свидетельства о предоставлении временного убежища на территории РФ',
          },
          { typeDoc: 'document_vnz', title: 'Вид на жительство' },
          {
            typeDoc: 'scan_translation_doc_passport',
            title:
              'Скан нотариально заверенного перевода паспорта (страница 2-3) (если в паспорте не содержится информация на русском языке)',
          },
        ]}
      />
    </div>
  )
}

export default ModeratorPassportDataPage
