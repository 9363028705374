import { useEffect, useState } from 'react'

import api from '@services/api'
import useAppSelector from '@hooks/useAppSelector'
import { selectLocalizedViewerProfile } from '@redux/actions/selectors'
import { RUSSIA_NATIONAL_ID } from '@common/constants'

const useCheckProfileValid = () => {
  const [loading, setLoading] = useState(true)
  const [valid, setValid] = useState(false)
  const profile = useAppSelector(selectLocalizedViewerProfile)

  useEffect(() => {
    setLoading(true)
    api.client
      .checkProfileStatus()
      .then((response) => {
        const { success } = response.data
        if (success) {
          setValid(true)
        }
      })
      .catch((err) => {
        if (
          profile?.userNational['0'].nationalityId == RUSSIA_NATIONAL_ID &&
          Object.keys(err.data.message).length == 1 &&
          Object.keys(err.data.message).shift() == 'legalRepresentative'
        ) {
          setValid(true)
        }
      })
      .finally(() => setLoading(false))
  }, [])

  return {
    loading,
    valid,
  }
}

export default useCheckProfileValid
