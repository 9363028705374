import React, { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import InputMask from 'react-input-mask'

import Link from 'components/generic/Link'

import ProfileAuth from './components/ProfileAuth'
import ProfileImage from './components/ProfileImage'
import terms from '@common/terms'
import ProfileForm from '@components/ProfileForm'
import ProfileSection from '@components/ProfileSection'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import usePersonalDataForm from '@hooks/usePersonalDataForm'
import usePersonalDataFormInitialization from '@hooks/usePersonalDataFormInitialization'
import useProfileRoutesContext from '@hooks/useProfileRoutesContext'
import useUploadDocuments from '@hooks/useUploadDocuments'
import { SelectOption } from '@models'
import { getLevelEducationKeys, getScenarioKeys } from '@redux/action-types'
import {
  selectIsQuestionnaireBlockProfile,
  selectLocalizedViewerProfile,
} from '@redux/actions/selectors'
import Checkbox from '@ui/Checkbox'
import FileUploader from '@ui/FileUploader'
import Input from '@ui/Input'
import RadioButton from '@ui/RadioButton'
import Select, { SingleValue } from '@ui/Select'
import { RUSSIA_NATIONAL_ID } from '@common/constants'

const PersonalDataForm = () => {
  const {
    watch,
    fields,
    control,
    onSubmit,
    setValue,
    clearErrors,
    snils,
    snilsRequired,
    isRussiaCitizenship,
    isAdult,
    maskMainPhone,
    maskAdditionalPhone,
    maskPartnerPhone,
    inRussia,
    formState: { errors },
  } = usePersonalDataForm()
  let { noNationality } = usePersonalDataForm()
  const {
    genderOptions,
    nationalSelectPropsRus,
    nationalSelectPropsIno,
    phoneCodesSelectProps,
    workOptions,
    maritalStatusOptions,
    personalData,
    partnersSelectProps,
  } = usePersonalDataFormInitialization({ watch, setValue })

  const readOnly = useAppSelector(selectIsQuestionnaireBlockProfile)
  const profileData = useAppSelector(selectLocalizedViewerProfile)
  const { onNextPageNavigate } = useProfileRoutesContext()
  const dispatch = useAppDispatch()
  const [noNational, setNoNational] = useState(noNationality)
  const {
    docs: snilsDocs,
    onDrop: onDropSnils,
    onChange: onChangeSnils,
    onDelete: onDeleteSnils,
    onUploadDocs: onUploadSnils,
    getUploadedDoc: getSnilsDoc,
    documentLoaded: snilsLoaded,
  } = useUploadDocuments('SNILS', terms.SNILS)

  useEffect(() => {
    dispatch(getLevelEducationKeys('levelEducation'))
    dispatch(getScenarioKeys('Scenario'))
  }, [])

  useEffect(() => {
    if (personalData?.noSnils != null || personalData?.noSnils != undefined) {
      !snilsLoaded && !personalData.noSnils && getSnilsDoc()
    }
  }, [personalData?.noSnils])

  useEffect(() => {
    if (!isRussiaCitizenship && !snils) {
      // Если НЕ РФ и не введен СНИЛС, то устанавливаем по умолчанию,
      // что Нет СНИЛС
      setValue('noSnils', true)
    } else {
      setValue('noSnils', !!personalData?.noSnils)
    }
  }, [profileData])

  useEffect(() => {
    if (profileData) {
      setValue('hasPhoto', Boolean(profileData.photo))
      if (profileData.photo) {
        clearErrors('hasPhoto')
      }
    }
  }, [profileData])

  //Проверка отсутвия гражданства
  let checkNational = false
  if (profileData?.userNational[0]) {
    checkNational = profileData?.userNational[0].nationality.code === '0'
  }
  function no_notionality() {
    setNoNational(!noNational)
  }
  noNationality = true
  useEffect(() => {
    if (checkNational) {
      noNationality = true
      setNoNational(noNationality)
    }
  }, [checkNational || noNational])

  const numberOnly = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault()
    }
  }
  const isEnName = isRussiaCitizenship && profileData?.code == '1'
  const isInoSignUpCode = !!profileData && profileData.code === '2'

  return (
    <ProfileForm
      onSubmit={
        readOnly
          ? (e) => {
              e.preventDefault()
              onNextPageNavigate()
            }
          : (e) => {
              onSubmit(e)
              if (Object.keys(errors).length === 0) {
                !watch('noSnils') && onUploadSnils(() => getSnilsDoc())
              }
            }
      }
    >
      <h3 className="profile-form__title form-title personal-data-form__title">
        {terms.YOUR_ID_IN_SYSTEM}: {profileData?.idWithPrefix}
      </h3>
      <div className="personal-data-form__top">
        <ProfileImage error={errors.hasPhoto?.message} />
        <ProfileAuth className="personal-data-form__auth" />
      </div>
      <div className="profile-form__fields">
        <Input
          className="profile-form__field"
          placeholder={terms.SURNAME}
          hint={terms.CYRILLIC_CHARACTERS_ONLY}
          markRequired={true}
          {...fields.surnameRu}
          disabled={readOnly}
          error={errors.surnameRu?.message}
        />
        {!isEnName && (
          <Input
            className="profile-form__field"
            placeholder={terms.SURNAME_IN_LATIN}
            markRequired={!isRussiaCitizenship}
            {...fields.surnameEn}
            disabled={readOnly}
            error={errors.surnameEn?.message}
          />
        )}
        <Input
          className="profile-form__field"
          placeholder={terms.NAME}
          hint={terms.CYRILLIC_CHARACTERS_ONLY}
          markRequired={true}
          {...fields.nameRu}
          disabled={readOnly}
          error={errors.nameRu?.message}
        />
        {!isEnName && (
          <Input
            className="profile-form__field"
            placeholder={terms.NAME_IN_LATIN}
            {...fields.nameEn}
            disabled={readOnly}
            markRequired={!isRussiaCitizenship}
            error={errors.nameEn?.message}
          />
        )}

        <div className="profile-form__field">
          <Input
            placeholder={terms.PATRONYMIC}
            hint={terms.CYRILLIC_CHARACTERS_ONLY}
            disabled={readOnly || watch('noPatronymic')}
            {...fields.patronymicRu}
            error={errors.patronymicRu?.message}
          />
          {!isRussiaCitizenship && (
            <p className={'input-container__hint'}>
              Если нет отчества — «поставьте галочку»
            </p>
          )}

          <Checkbox
            text={terms.NO_PATRONYMIC}
            className="profile-form__checkbox"
            {...fields.noPatronymic}
            disabled={readOnly}
          />
        </div>
        {!isEnName && (
          <Input
            className="profile-form__field"
            placeholder={terms.PATRONYMC_IN_LATIN}
            disabled={readOnly || watch('noPatronymic')}
            {...fields.patronymicEn}
            error={errors.patronymicEn?.message}
          />
        )}
        <Controller
          name="gender"
          control={control}
          render={({ field }) => (
            <Select<SelectOption>
              options={genderOptions}
              placeholder={terms.GENDER}
              className="profile-form__field"
              markRequired={true}
              {...(field as unknown)}
              isDisabled={readOnly}
              error={errors.gender?.message}
            />
          )}
        />
        {/*
        {isEnName && (
          <Controller
            name="workId"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                placeholder={terms.JOB}
                className="profile-form__field profile-form__input--fb-50"
                options={workOptions}
                {...(field as unknown)}
                isDisabled={readOnly}
                error={errors.workId?.message}
              />
            )}
          />
        )}
        */}
        {/*
        <Controller
          name="maritalStatusId"
          control={control}
          render={({ field }) => (
            <Select<SelectOption>
              placeholder={terms.MARITAL_STATUS}
              className="profile-form__field profile-form__input--fb-50"
              options={maritalStatusOptions}
              markRequired={true}
              {...(field as unknown)}
              isDisabled={readOnly}
              error={errors.maritalStatusId?.message}
            />
          )}
        />
        */}
        {/*
        {isEnName && (
          <Input
            className="profile-form__field"
            placeholder={terms.CHILDREN_AMOUNT}
            type="number"
            min="0"
            max="99"
            {...fields.amountChild}
            disabled={readOnly}
            error={errors.amountChild?.message}
          />
        )}
        */}
        <Input
          className="profile-form__field"
          placeholder={terms.DATE_OF_BIRTH}
          type="date"
          max="100"
          markRequired={true}
          {...fields.dateBirthday}
          disabled={readOnly}
          error={errors.dateBirthday?.message}
        />
        {/*{!isAdult && (*/}
        {/*  <div className="profile-form__field profile-form__field--container download__field">*/}
        {/*    <p>*/}
        {/*      Скачайте документы. Подписанные версии необходимо загрузить в раздел*/}
        {/*      "Дополнительные файлы"*/}
        {/*    </p>*/}
        {/*    <div className="download__field-links">*/}
        {/*      <Link*/}
        {/*        href="/documents/Согласие Приложение к заявлению о приеме в СПбГУ персональные данные для лица до 18 лет.docx"*/}
        {/*        target="_blank"*/}
        {/*      >*/}
        {/*        Согласие на обработку персональных данных*/}
        {/*      </Link>*/}
        {/*      <Link*/}
        {/*        href="/documents/ФОРМА СОГЛАСИЯ на обработку персональных данных  ДЛЯ РАСПРОСТРАНЕНИЯ.docx"*/}
        {/*        target="_blank"*/}
        {/*      >*/}
        {/*        Форма согласия*/}
        {/*      </Link>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}
        <div className="profile-form__field profile-form__field--container">
          <Controller
            name="phoneCode"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                placeholder={terms.COUNTRY_CODE}
                className="profile-form__input profile-form__input--fb-33"
                markRequired={isRussiaCitizenship}
                {...phoneCodesSelectProps}
                {...(field as unknown)}
                isDisabled={readOnly}
                error={errors.phoneCode?.message}
                styles={{ menu: (base) => ({ ...base, minWidth: '300px' }) }}
                components={{ SingleValue }}
              />
            )}
          />
          <div
            className="input-container profile-form__field input-container--active position"
            style={{ flexBasis: '64%' }}
          >
            <div className="input-container__inner input-container__masked">
              <InputMask
                mask={maskMainPhone}
                className="input-container__input"
                {...fields.mobile}
                disabled={readOnly}
                onKeyPress={numberOnly}
                error={errors.mobile?.message}
              />
              <label className="input-container__placeholder input-container__placeholder--required">
                {terms.MOBILE_PHONE}
              </label>
              {errors.mobile?.message && (
                <span className={'error'}>{errors.mobile?.message}</span>
              )}
            </div>
          </div>
        </div>
        <div className="profile-form__checkbox_fb-47">
          <Controller
            name="nationality"
            control={control}
            render={({ field }) =>
              isInoSignUpCode ? (
                <Select<SelectOption>
                  placeholder={terms.CITIZENSHIP}
                  className="profile-form__field"
                  markRequired={!noNational}
                  {...nationalSelectPropsIno}
                  {...(field as unknown)}
                  isDisabled={readOnly || noNational}
                  error={errors.nationality?.message}
                />
              ) : (
                <Select<SelectOption>
                  placeholder={terms.CITIZENSHIP}
                  className="profile-form__field"
                  markRequired={!noNational}
                  {...nationalSelectPropsRus}
                  {...(field as unknown)}
                  isDisabled={readOnly || noNational}
                  error={errors.nationality?.message}
                />
              )
            }
          />
          {profileData?.userNational[0] &&
            profileData?.userNational[0].nationalityId !== RUSSIA_NATIONAL_ID && (
              <Checkbox
                text={terms.NO_NATIONALITY}
                className="profile-form__checkbox"
                {...fields.noNationality}
                onClickCapture={no_notionality}
                defaultChecked={
                  profileData?.userNational[0] &&
                  profileData?.userNational[0].nationality.code === '0'
                }
                disabled={isRussiaCitizenship || readOnly}
              />
            )}
        </div>
        {isRussiaCitizenship && (
          <Controller
            name="recruitingCompany"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                className="profile-form__field"
                placeholder={terms.RECRUITING_COMPANY}
                {...partnersSelectProps}
                isDisabled={readOnly}
                {...(field as unknown)}
              />
            )}
          />
        )}
        {isRussiaCitizenship && (
          <div className="profile-form__field profile-form__field--container">
            <Controller
              name="partnerOrganizationCode"
              control={control}
              render={({ field }) => (
                <Select<SelectOption>
                  placeholder={terms.COUNTRY_CODE}
                  className="profile-form__input profile-form__input--fb-33"
                  {...phoneCodesSelectProps}
                  {...(field as unknown)}
                  isDisabled={readOnly}
                  styles={{ menu: (base) => ({ ...base, minWidth: '300px' }) }}
                  components={{ SingleValue }}
                />
              )}
            />
            <div
              className="input-container profile-form__field input-container--active position"
              style={{ flexBasis: '64%' }}
            >
              <div className="input-container__inner">
                <InputMask
                  mask={maskPartnerPhone}
                  className="input-container__input"
                  {...fields.partnerOrganizationPhone}
                  disabled={readOnly}
                  onKeyPress={numberOnly}
                  error={errors.mobile?.message}
                />
                <label className="input-container__placeholder">
                  {terms.PARTNER_ORGANIZATION_PHONE}
                </label>
              </div>
            </div>
          </div>
        )}
        {isRussiaCitizenship && (
          <Input
            className="profile-form__field"
            placeholder={terms.PARTNER_ORGANIZATION_EMAIL}
            {...fields.partnerOrganizationEmail}
            disabled={readOnly}
            error={errors.partnerOrganizationEmail?.message}
          />
        )}
        <div className="profile-form__field">
          <Input
            className="profile-form__field"
            placeholder={terms.ADDITIONAL_EMAIL_IF_ANY}
            {...fields.dopEmail}
            disabled={readOnly}
            error={errors.dopEmail?.message}
          />
          {!isRussiaCitizenship && (
            <RadioButton className="profile-form__checkbox">
              <div className="input-container__placeholder--required">
                <b>{terms.IN_RUSSIA}</b>
              </div>
              <RadioButton.Radio
                {...fields.inRussia}
                text={terms.YES}
                disabled={readOnly}
                value="1"
                name="inRussia"
                checked={inRussia}
              />
              <RadioButton.Radio
                {...fields.inRussia}
                text={terms.NO}
                disabled={readOnly}
                value="0"
                name="inRussia"
                checked={!inRussia}
              />
            </RadioButton>
          )}
        </div>
        <div className="profile-form__field profile-form__field--container">
          <Controller
            name="additionalPhoneCode"
            control={control}
            render={({ field }) => (
              <Select<SelectOption>
                placeholder={terms.COUNTRY_CODE}
                className="profile-form__input profile-form__input--fb-33"
                {...phoneCodesSelectProps}
                {...(field as unknown)}
                isDisabled={readOnly}
                error={errors.additionalPhoneCode?.message}
                styles={{ menu: (base) => ({ ...base, minWidth: '300px' }) }}
                components={{ SingleValue }}
              />
            )}
          />
          <div className="input-container profile-form__field input-container--active position profile-form__input--fb-66">
            <div className="input-container__inner">
              <InputMask
                mask={maskAdditionalPhone}
                className="input-container__input"
                {...fields.additionalMobile}
                disabled={readOnly}
                onKeyPress={numberOnly}
                error={errors.additionalMobile?.message}
              />
              <label className="input-container__placeholder input-container__placeholder">
                {terms.ADDITIONAL_MOBILE_PHONE_POSTSCRIPT}
              </label>
            </div>
          </div>
          {/*<Input
            className="profile-form__input profile-form__input--fb-66"
            placeholder={terms.ADDITIONAL_MOBILE_PHONE}
            type="tel"
            {...fields.additionalMobile}
            maxLength={10}
            onKeyPress={numberOnly}
            disabled={readOnly}
            error={errors.additionalMobile?.message}
          />*/}
        </div>
        <div className="profile-form__field">
          <Input
            placeholder={terms.SNILS}
            markRequired={snilsRequired}
            {...fields.snils}
            disabled={readOnly || watch('noSnils')}
            error={errors.snils?.message}
          />
          <Checkbox
            text={terms.NO_SNILS}
            className="profile-form__checkbox"
            {...fields.noSnils}
            disabled={readOnly || !isRussiaCitizenship}
          />
        </div>

        <Input
          className="profile-form__field"
          placeholder={terms.DATE_OF_ISSUE_SNILS}
          type="date"
          max="100"
          markRequired={snilsRequired}
          {...fields.dateSnils}
          disabled={readOnly || watch('noSnils') || watch('noDateSnils')}
          error={errors.dateSnils?.message}
        />

        {!watch('noSnils') && (
          <div className="snils-upload">
            <Checkbox
              text={terms.NO_DATE_SNILS}
              className="profile-form__checkbox no-date-snils"
              {...fields.noDateSnils}
              disabled={readOnly || watch('noSnils')}
            />
            <ProfileSection title="Копия СНИЛС" markRequired={watch('noSnils')}>
              <FileUploader
                files={snilsDocs || []}
                onDrop={onDropSnils}
                onChange={onChangeSnils}
                onDelete={onDeleteSnils}
                disabled={readOnly || watch('noSnils')}
              />
            </ProfileSection>
          </div>
        )}
      </div>
    </ProfileForm>
  )
}

export default PersonalDataForm
