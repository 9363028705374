import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { RUSSIA_NATIONAL_ID, WEB_METHOD_APPLICATION_ID } from '@common/constants'
import { mimeTypes } from '@common/manual'
import regexp from '@common/regexp'
import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useDownloadTemplateDocument from '@hooks/useDownloadTemplateDocument'
import usePaginatedNationalsIno from '@hooks/usePaginatedNationalsIno'
import usePaginatedNationalsRus from '@hooks/usePaginatedNationalsRus'
import useProfileApiHandling from '@hooks/useProfileApiHandling'
import useProfileRoutesContext from '@hooks/useProfileRoutesContext'
import {
  normalizeYearTo4Characters,
  moreThan18age,
  getMobileValidators,
  getPassportSeries,
  getPassportNumber,
  getDivisionCode,
} from '@lib/validators'
import { LegalRepresentative, SelectOption } from '@models'
import { getLegalRepresentative } from '@redux/action-types'
import { selectLocalizedViewerProfile } from '@redux/actions/selectors'
import api from '@services/api'
import issuingAuthorityApi from '@services/api/issuing-authority-api'

const useLegalRepresentativeForm = (passportids) => {
  const {
    register,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    setValue,
    trigger,
    getValues,
    ...rest
  } = useForm<LegalRepresentative>({ mode: 'all' })

  const dispatch = useAppDispatch()

  const { callApi, loading } = useProfileApiHandling({
    setError,
    apiFn: api.client.updateLegalRepresentative,
    onSuccessCb: () => dispatch(getLegalRepresentative()),
  })
  const { onNextPageNavigate } = useProfileRoutesContext()

  const profile = useAppSelector(selectLocalizedViewerProfile)
  const nationalityId = profile?.userNational['0'].nationalityId

  const nationalSelectProps = usePaginatedNationalsRus()
  const national = watch('nationality') as unknown as SelectOption | undefined
  const isRussia = national?.value === RUSSIA_NATIONAL_ID

  const listIssuingAuthority: SelectOption[] = []

  const IssuingAuthorityOnChange = (event) => {
    listIssuingAuthority.length = 0
    setValue('whoIssueDocument', '')
    if (event.target.value.length === 7) {
      issuingAuthorityApi
        .getIssuingAuthority(event.target.value)
        .then(function (response) {
          let i = 1

          response.data.suggestions.forEach((item) => {
            listIssuingAuthority.push({ value: i, label: item.value })
            i += 1
          })
        })
    }
  }

  const phoneCode = watch('phoneCode') as unknown as
    | (SelectOption & { chipLabel: string })
    | undefined

  const [required, setRequired] = useState(false)

  // useEffect(() => {
  //   if (profile?.dateBirthday) {
  //     setRequired(!moreThan18age(profile.dateBirthday))
  //   }
  // }, [profile])

  const requiredParams = {
    required: { value: required, message: terms.REQUIRED_FIELD },
  }

  const typeDocId = watch('typeIdentityDocumentId') as unknown as
    | SelectOption
    | undefined
  const isSeriesRequired =
    typeDocId?.value === passportids.russia_citizen_passport_code.tdId

  const isRussianPassport =
    typeDocId?.value === passportids.russia_citizen_passport_code.tdId

  const isDateOfExpirationExists =
    typeDocId?.value !== passportids.russia_citizen_passport_code.tdId

  const fields = {
    typeIdentityDocumentId: register('typeIdentityDocumentId', {
      //  required: terms.REQUIRED_FIELD,
      onChange: (e) => {
        e.target.value && clearErrors('serialDocument')
        e.target.value && clearErrors('numberDocument')
        if (e.target.value.value == 1) {
          setValue('documentValidPeriod', undefined)
        }
        setValue('whoIssueDocument', '')
        setValue('divisionCode', '')
      },
    }),
    serialDocument: register('serialDocument', {
      //  required: isSeriesRequired ? terms.REQUIRED_FIELD : '',
      ...getPassportSeries(isSeriesRequired),
    }),
    numberDocument: register('numberDocument', {
      //  required: terms.REQUIRED_FIELD,
      ...getPassportNumber(isRussianPassport),
    }),
    divisionCode: register('divisionCode', {
      //  required: { value: isRussianPassport, message: terms.REQUIRED_FIELD },
      ...getDivisionCode(),
    }),
    dateOfIssue: register('dateOfIssue', {
      //  required: terms.REQUIRED_FIELD,
      onChange: ({ target: { value } }) =>
        value && setValue('dateOfIssue', normalizeYearTo4Characters(value)),
    }),
    whoIssueDocument: register('whoIssueDocument', {
      //  required: terms.REQUIRED_FIELD,
    }),
    documentValidPeriod: register('documentValidPeriod', {
      //  required: { value: isDateOfExpirationExists, message: terms.REQUIRED_FIELD },
      onChange: ({ target: { value } }) =>
        value && setValue('documentValidPeriod', normalizeYearTo4Characters(value)),
    }),

    placeBirthday: register('placeBirthday', {
      // required: { value: nationalityId != 1, message: terms.REQUIRED_FIELD },
    }),
    nationalityId: register('nationalityId', {
      //  required: terms.REQUIRED_FIELD,
      onChange: (event) => {
        //const nationalId: SelectOption | null = event.target.value
        //const isNationalRussia = nationalId?.value === RUSSIA_NATIONAL_ID
        // if (isNationalRussia) {
        //   setValue('haveVnz', false)
        // }
      },
    }),
    countryOfBirth: register('countryOfBirth', {
      //required: terms.REQUIRED_FIELD,
    }),

    name: register('name', {
      required: {
        value: nationalityId != RUSSIA_NATIONAL_ID,
        message: terms.REQUIRED_FIELD,
      },
      pattern: { value: regexp.ru, message: terms.CYRILLIC_CHARACTERS_ONLY },
    }),
    surname: register('surname', {
      required: {
        value: nationalityId != RUSSIA_NATIONAL_ID,
        message: terms.REQUIRED_FIELD,
      },
      pattern: { value: regexp.ru, message: terms.CYRILLIC_CHARACTERS_ONLY },
    }),
    patronymic: register('patronymic', {
      required: {
        value: !watch('noPatronymic') && nationalityId != RUSSIA_NATIONAL_ID,
        message: terms.REQUIRED_FIELD,
      },
      pattern: { value: regexp.ru, message: terms.CYRILLIC_CHARACTERS_ONLY },
    }),
    nameEn: register('nameEn', {
      required: {
        value: !isRussia && nationalityId != RUSSIA_NATIONAL_ID,
        message: terms.REQUIRED_FIELD,
      },
      pattern: { value: regexp.en, message: terms.LATIN_CHARACTERS_ONLY },
    }),
    surnameEn: register('surnameEn', {
      required: {
        value: !isRussia && nationalityId != RUSSIA_NATIONAL_ID,
        message: terms.REQUIRED_FIELD,
      },
      pattern: { value: regexp.en, message: terms.LATIN_CHARACTERS_ONLY },
    }),
    patronymicEn: register('patronymicEn', {
      pattern: { value: regexp.en, message: terms.LATIN_CHARACTERS_ONLY },
    }),

    dateBirthday: register('dateBirthday', {
      ...requiredParams,
      ...(required && {
        validate: (value) =>
          value ? moreThan18age(value) || terms.REPRESENTATIVE_OVER_18 : false,
      }),
      onChange: ({ target: { value } }) =>
        value && setValue('dateBirthday', normalizeYearTo4Characters(value)),
    }),
    nationality: register('nationality', {
      required: {
        value: nationalityId != RUSSIA_NATIONAL_ID,
        message: terms.REQUIRED_FIELD,
      },
      // onChange: ({ target: { value } }) =>
      //   value?.value === RUSSIA_NATIONAL_ID
      //     ? setValue('noSnils', false)
      //     : !snils && setValue('noSnils', true),
    }),
    mobile: register('mobile', {
      required: terms.REQUIRED_FIELD,
      ...getMobileValidators(phoneCode?.chipLabel),
    }),
    phoneCode: register('phoneCode', {
      required: { value: isRussia, message: terms.REQUIRED_FIELD },
      onChange: ({ target: { value } }) => value && clearErrors('mobile'),
    }),
    dopEmail: register('dopEmail', {
      pattern: { value: regexp.email, message: terms.VALID_EMAIL },
    }),
    relationDegreeId: register('relationDegreeId', requiredParams),
    noPatronymic: register('noPatronymic', {
      onChange: (e) => {
        const { checked } = e.target
        if (checked) {
          clearErrors('patronymic')
          clearErrors('patronymicEn')
          setValue('patronymic', '')
          setValue('patronymicEn', '')
        }
      },
    }),
  }
  const { downloadTemplateDoc } = useDownloadTemplateDocument()

  const onDownloadTemplateDoc = () => {
    downloadTemplateDoc(
      'TARGET_AGREEMENT',
      mimeTypes.pdf,
      'pdf',
      terms.CONSENT_OF_PERSONAL_DATA_PROCESSING
    )
  }

  const onSubmit = handleSubmit((data: any) => {
    if (nationalityId == RUSSIA_NATIONAL_ID) {
      const normalizedData: LegalRepresentative = {
        ...data,

        divisionCode: data.divisionCode || null,

        nationality_id: data.nationality?.value || null,
        country_of_birth_id: data.countryOfBirth?.value,

        phoneCode: data.phoneCode?.chipLabel || null,

        mobile: data.mobile
          ? (data.phoneCode?.chipLabel ? `${data.phoneCode?.chipLabel} ` : '') +
            data.mobile.replace(/\s/g, '')
          : null,
        relationDegreeId: data.relationDegreeId?.value || null,
        whoIssueDocument:
          typeof data.whoIssueDocument === 'string'
            ? data.whoIssueDocument
            : data.whoIssueDocument?.label,
      }
      callApi(normalizedData)
    } else {
      const normalizedData: LegalRepresentative = {
        ...data,

        divisionCode: data.divisionCode || null,

        nationality_id: data.nationality?.value || null,
        country_of_birth_id: data.countryOfBirth?.value,

        phoneCode: data.phoneCode?.chipLabel || null,
        mobile: data.mobile
          ? (data.phoneCode?.chipLabel ? `${data.phoneCode?.chipLabel} ` : '') +
            data.mobile.replace(/\s/g, '')
          : null,
        relationDegreeId: data.relationDegreeId?.value || null,
        whoIssueDocument:
          typeof data.whoIssueDocument === 'string'
            ? data.whoIssueDocument
            : data.whoIssueDocument?.label,
      }
      callApi(normalizedData)
    }
  })

  return {
    nationalSelectProps,
    isRussia,
    fields,
    onSubmit,
    loading,
    setValue,
    getValues,
    watch,
    required,
    isSeriesRequired,
    isRussianPassport,
    isDateOfExpirationExists,
    listIssuingAuthority,
    IssuingAuthorityOnChange,
    onDownloadTemplateDoc,
    ...rest,
  }
}

export default useLegalRepresentativeForm
